import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [createPersistedState({
      storage: window.sessionStorage
    })],
	state: {
		count:0,
		imgSrc: "",
		selectSyms: [],
	},
	mutations: {
		add(state){
			state.count ++
		},
		decrease(state){
			state.count --
		},
		changeCount(state,num){
			console.log('num', num);
			state.count = num;
		},
		getImgSrc(state, src){
			console.log("src", src);
			state.imgSrc = src;
		},
		getSelectSys(state, select){
			state.selectSyms = select;
		}
	},
	actions: {
		delayAdd(context){
			setTimeout(()=>{
				context.commit("add")
			},1000);
		}
	},
})