<template>
    <div class="background">
        <div style="font-size: 80px;color: #F2F6FC;">CareRare 罕见病特征分析辅诊方案</div>
        <div style="margin-top: 30%;cursor: pointer;">
            <!-- <img src="../assets/next-button.png" /> -->


            <div class="start-button"   @click="show = true" :style="{left: screenWidth/2+100+'px'}">
                <span style="font-size: 60px;color: #FFFFFF">登录</span>
            </div>
           
            <div class="start-button" @click="tryFunc" :style="{left: screenWidth/2-300+'px'}">
                <span style="font-size: 60px;color: #FFFFFF">体验</span>
            </div>
            <div @click="instructionPath" class="instruct" :style="{left: screenWidth/2-50+'px'}">
                <span style="font-size: 30px;color: #FFFFFF;margin-top: 10px;">使用说明</span>
            </div>
        </div>
        <div class="footer">
            <span style="color: #8f8f8f;">谢谢体验</span>
        </div>
        <el-dialog title="登录" :visible.sync="show" width="30%" :append-to-body="true" style="z-index:999">
            <el-form ref="loginRef" :model="loginForm" class="login-form">
                <h3 class="title">IV-遗传病管理系统</h3>
                <el-form-item prop="loginId">
                    <el-input v-model="loginForm.loginId" type="text" auto-complete="off" placeholder="账号">
                        <template #prefix>
                            <svg-icon icon-class="user" class="el-input__icon input-icon" />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="loginPwd">
                    <el-input v-model="loginForm.loginPwd" type="password" auto-complete="off" placeholder="密码"
                        @keyup.enter="handleLogin">
                        <template #prefix>
                            <svg-icon icon-class="password" class="el-input__icon input-icon" />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item style="width:100%;">
                    <el-button :loading="loading" size="medium" type="primary" style="width:100%;"
                        @click.prevent="handleLogin">
                        <span v-if="!loading">登 录</span>
                        <span v-else>登 录 中...</span>
                    </el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
// import backGround from '../assets/BrowserPreview_tmp%20(3).gif'
const baseUrl = 'https://iv.apisev.cn'
import axios from 'axios';
import Cookies from 'js-cookie'
export default {
    name: "Starting",
    data() {
        return {
            screenWidth: '',
            screenHeight: '',
            show: false,
            loginForm: {},
            loading: false
        };
    },
    mounted() {
        // let token = Cookies.get("token");
        // if(token != undefined){
        //     this.isLogin = true
        // }
        this.screenWidth = document.documentElement.clientWidth;
        this.screenHeight = document.documentElement.clientHeight;
        // this.clientHeight = `${document.documentElement.clientHeight}`
        // console.log("document num",this.screenWidth,this.screenHeight)
        window.onresize = () => {
            console.log("width heigth", document.documentElement.clientWidth, document.documentElement.clientHeight)
            return (() => {
                this.screenWidth = document.documentElement.clientWidth;
                this.screenHeight = document.documentElement.clientHeight;
            })();
        };
    },
    methods: {
        handleLogin() {
            console.log(this.loginForm)
            axios({
                url: baseUrl + '/qlogin',
                method: 'post',
                data: { ...this.loginForm }
            }).then(res => {
                if (res.data.code == 200) {
                    Cookies.set("token", res.data.data.token)
                    this.$message({
                        message: '欢迎您:' + res.data.data.nickName,
                        type: 'success'
                    });
                    this.routePath();
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }

            })

        },
        // 展示登录弹窗
        showLogin() {

        },
        tryFunc(){
            Cookies.remove('token');
            this.routePath();
        },
        routePath() {
            this.$store.commit('changeCount', 1);
            this.$router.push('container/camera');
        },
        instructionPath() {
            let routeData = this.$router.resolve({
                path: "/instructions"
            })
            window.open(routeData.href, '_blank')
        }
    },
};

</script>

<style>
.background {
    background: url("../assets/background.gif");
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
}

.start-button {
    height: 85px;
    width: 220px;
    background: url(../assets/next-button.png) no-repeat;
    position: absolute;
}

.footer {
    background: url("../assets/footer.png");
    width: 100%;
    height: 35px;
    position: fixed;
    background-size: 100% 100%;
    bottom: 0;
}

.instruct {
    position: absolute;
    margin-top: 90px;
}
</style>
