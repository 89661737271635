<template>
    <el-dialog title="诊断结果" width="600px" :close-on-click-modal="false" :visible.sync="show" @close="show = false"
        append-to-body>
        <div>
            <el-table :data="listData" v-model="show">
                <el-table-column label="遗传病ID" align="center" prop="id" />
                <el-table-column label="遗传病名称" align="center" prop="disease_en" />
                <el-table-column label="遗传病名称(CN)" align="center" prop="disease_cn" />
                <el-table-column label="匹配度" align="center" prop="probility" />
        

            </el-table>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="show = false" size="large">取消</el-button>
                <el-button type="primary" @click="show = false" size="large">确认</el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script>
export default {
    name: "Res",
    props: {//props列表
        listData: {
            type: Array
        },
        show: {
            type: Boolean
        },

    },
    created() {

    }
}
</script>
<style>

</style>