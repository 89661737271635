<template>
  <div>
    <el-form ref="queryRef" :inline="true" style="background-color:white;float:left;width: 100%;">
      <el-form-item label="患者姓名" prop="patientName">
        <el-select v-model="patientName" @change="selectPatient" filterable :remote="true" :remote-method="matchPatient"
          placeholder="请输入患者姓名">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="Search" size="mini" @click="handleQuery">搜索</el-button>
      </el-form-item>
    </el-form>
    <!-- 展示列表 -->
    <el-table :data="tableData" v-loading="loading">
      <el-table-column label="病人姓名" align="center" prop="patientName" />
      <el-table-column label="病人手机" align="center" prop="patientMobile" show-overflow-tooltip />

      <el-table-column label="选择症状" align="center" prop="selectSyms" show-overflow-tooltip />
      <el-table-column label="诊断图" align="center" key="imageName" prop="imageName" width="100">
        <template slot-scope="scope">
          <viewer> <img :src="'https://ai.visioncarewsk.com/show_photo?photo_name='+scope.row.imageName" preview width="80" /></viewer>
         
        </template>
      </el-table-column>
      <el-table-column label="诊断结果" align="center" class-name="small-padding fixed-width">
        <template #default="scope">
          <el-button type="success" @click="showDetail(scope.row)">查看</el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column label="备注" align="center" prop="remark" show-overflow-tooltip /> -->
      <el-table-column label="诊断时间" align="center" prop="createdTime" width="160" />
    </el-table>
    <pagination v-show="total>0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
      @pagination="getList" />
    <viewRes :listData="resList" :show="resShow"></viewRes>
  </div>
</template>

<script>
// import { checkbeforepersonalphoto } from "../api/api";
// import cuttingdialog from "./CuttingDialog.vue";
// import Cookie from 'js-cookie';
import viewRes from '../components/viewRes.vue';
import axios from 'axios';
const baseUrl = 'https://iv.apisev.cn'
export default {
  name: "his",
  components: { viewRes },
  props: {
    msg: String,
  },
  mounted() {
  },
  data() {
    return {
      //选择的症状
      loading: false,
      form: {},
      options: [],
      selectedPatientId: null,
      resShow: false,
      resList: [],
      total: 0,
      patientName: null,
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        patientId: null
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    showDetail(e) {
      console.log(e)
      let result = JSON.parse(e.result);
      this.resList = result;
      console.log(result)
      this.resShow = true;
    },
    selectPatient(e) {
      console.log(e)
      this.selectedPatientId = e;
      this.queryParams.patientId = e;
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      axios({
        url: baseUrl + '/iv/history',
        method: 'get',
        params: this.queryParams
      }).then(res => {
        console.log(res)
        this.total = res.data.data.totalElements;
        this.tableData = res.data.data.content;
        this.loading = false;
      })
    },
    // 匹配患者
    matchPatient(e) {
      let query = e;
      console.log(query)
      axios({
        url: baseUrl + '/iv/patient/all?name=' + query,
        method: 'get'
      }).then(res => {
        console.log(res)
        let tarr = res.data.data;
        tarr = tarr.map(ele => {
          ele.label = ele.patientName + '(' + ele.patientId + ')'
          ele.value = ele.id
          return ele;
        })
        this.options = tarr
      })
    },
  },
};
</script>
<style scoped>

</style>
  