import Vue from 'vue'
import VueRouter from 'vue-router'
import Start from '../components/Starting.vue'
import instruct from '../components/instruction.vue'
import functionView from '../components/functionView.vue'
import Camera from '../components/camera.vue'
import Search from '../components/search.vue'
import Table from '../components/table.vue'
import History from '../components/history.vue';
Vue.use(VueRouter)

export default new VueRouter({
	mode: 'history',
	routes: [
	{
		path: '/instructions',
		name: 'instruct',
		component: instruct
	},
	{
		path:'/history',
		name: 'history',
		component: History,
	},
	{
		path: '/container',
		name: 'functionView',
		component: functionView,
		children: [
			{
				path: '/container/camera',
				name: 'Camera',
				component: Camera
			},
			{
				path: '/container/search',
				name: 'Search',
				component: Search
			},
			{
				path: '/container/table',
				name: 'Table',
				component: Table
			},
		]
		
	},
	{
		path: '/',
		name: 'Start',
		component: Start
	},]
})
