
<template>
    <div>
    <div>
        <el-row>
            <img src="../assets/cutting.png" style="float: right;position: absolute;top: 20px;right: 35px;cursor: pointer;" @click="editCropper()"/>
            <img src="../assets/method-background.png" style="float: right;cursor: pointer;" @click="editCropper()"/>
        </el-row>
        <el-row>
            <el-button @click="editCropper()" type="text" size="small" style="float: right;margin-right: 15px;font-size: 30px;">裁剪</el-button>
        </el-row>
    </div>
    <div>
        <el-dialog title="裁剪图片" 
        :visible.sync="open" width="80%" 
        @opened="modalOpened"  
        @close="closeDialog">
		<el-row>
			<span>（需要注意的是，若截图框内包含背景方框，则会补全黑色）</span>
		</el-row>
        <el-row :gutter="20">
            <el-col :span="12" :style="{height: '450px'}">
                <VueCropper
                ref="cropper"
                :img="url"
                :info="true"
                :autoCrop="options.autoCrop"
                :autoCropWidth="options.autoCropWidth"
                :autoCropHeight="options.autoCropHeight"
                :fixedBox="options.fixedBox"
                @realTime="realTime"
                v-if="visible"></VueCropper>
            </el-col>
            <el-col :span="12" :style="{height: '450px',width: '450px'}">
                <div class="avatar-upload-preview">
                    <img
                      class="canvas"
                      width="450"
                      height="400"
                      src="https://img-blog.csdnimg.cn/20210126152753150.png"
                      style="z-index: 999; position: absolute;left: -30px;"
                    />
                  <img :src="previews.url" :style="previews.img" />
                </div>
            </el-col>
        </el-row>
        <br />
        <el-row :gutter="20">
            <el-col :span="1" :offset="1">
              <el-button icon="el-icon-plus" size="small" @click="changeScale(1)"></el-button>
            </el-col>
            <el-col :span="1" :offset="1">
              <el-button icon="el-icon-minus" size="small" @click="changeScale(-1)"></el-button>
            </el-col>
            <el-col :span="1" :offset="1">
              <el-button icon="el-icon-refresh-left" size="small" @click="rotateLeft()"></el-button>
            </el-col>
            <el-col :span="1" :offset="1">
              <el-button icon="el-icon-refresh-right" size="small" @click="rotateRight()"></el-button>
            </el-col>
            <el-col :span="1" :offset="8">
              <el-button type="primary" size="small" @click="uploadImg()">提 交</el-button>
            </el-col>
        </el-row>
        </el-dialog>
    </div>
    </div>
</template>

<script>
import { VueCropper } from "vue-cropper";

export default {
    components: { VueCropper },
    props: {
      url: String,
    },
    data() {
        return {
            // 是否显示弹出层
            open: false,
            // 是否显示cropper
            visible: false,
            
            options: {
              img: "", //裁剪图片的地址
              autoCrop: true, // 是否默认生成截图框
              autoCropWidth: 400, // 默认生成截图框宽度
              autoCropHeight: 320, // 默认生成截图框高度
              fixedBox: true // 固定截图框大小 不允许改变
            },
            previews: {}
        };
    },
    methods:{
        editCropper() {
          this.open = true;
        },
        modalOpened() {
          this.visible = true;
        },
        closeDialog() {
          this.visible = false;
        },
        realTime(data) {
            console.log('realTime(data)', data);
          this.previews = data;
        },
        changeScale(num) {
          num = num || 1;
          this.$refs.cropper.changeScale(num);
        },
        // 向左旋转
        rotateLeft() {
          this.$refs.cropper.rotateLeft();
        },
        // 向右旋转
        rotateRight() {
          this.$refs.cropper.rotateRight();
        },
        uploadImg() {
          this.$refs.cropper.getCropData(data => {
            console.log("cutting-data", data);
            this.$emit("urlchange", data);
          });
          this.open = false;
        },
    },
};
    
</script>

<style>
    .avatar-upload-preview {
        position: absolute;
        top: 50%;
        transform: translate(10%, -50%);
        width: 400px;
        height: 320px;
        /* border-radius: 50%; */
        box-shadow: 0 0 4px #ccc;
        overflow: hidden;
    }
</style>