<template>
  <div>
      <el-row>
          <div class="box-searching" :style="{width:screenWidth/10*8+'px',height:screenHeight-300+'px'}">
              <el-cascader
               @change="casChange"
                :show-all-levels="false"
                :options="options"
                :props="props"
                clearable
                filterable
                style="float: left;margin-top: 5px;margin-left: 10px;"
                :style="{width: screenWidth/5*4-20+'px'}"
              ></el-cascader>
          </div>
      </el-row>
      <el-row>
          <div @click="nextStep" style="position: absolute;" :style="{right: screenWidth/10+'px'}">
              <img src="../assets/next-button.png" style="cursor: pointer;"/>
              <span class="nextstep">下一步</span>
          </div>
          <div @click="preStep" style="position: absolute;" :style="{left: screenWidth/10+'px'}">
              <img src="../assets/next-button.png" style="cursor: pointer;"/>
              <span class="nextstep">上一步</span>
          </div>
      </el-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Search",
  data() {
    return {
        screenWidth: '',
        screenHeight: '',
        selectSyms: [],
      options: [],
      props: {
        multiple: true,
      },
    };
  },
  created() {
    //请求后台并且进行groupBy操作
    this.reqSym();
  },
  mounted() {
    this.screenWidth = document.documentElement.clientWidth;
    this.screenHeight = document.documentElement.clientHeight;
    // this.clientHeight = `${document.documentElement.clientHeight}`
    // console.log("document num",this.screenWidth,this.screenHeight)
    window.onresize = () => {
        console.log("width heigth", document.documentElement.clientWidth, document.documentElement.clientHeight)
      return (() => {
        this.screenWidth = document.documentElement.clientWidth;
        this.screenHeight = document.documentElement.clientHeight;
      })();
    };
  },

  methods: {
    reqSym() {
      axios.get("https://iv.apisev.cn/iv/symptoms/simple").then((res) => {
        console.log('res', res,this.$store.state.count);
        this.options = res.data.data;
      });
    },
    handleFilter(node, key) {
      console.log(key);
    },
    // casSearch(node, key) {
    //   console.log(node, key)
    // },
    casChange(e) {
      console.log(e);
      let tarr = e;
      let tmp = [];
      tarr.forEach(ele=>{
        tmp.push(ele[1])
      })
      this.selectSyms = tmp;
      console.log(tmp)
    },
    //
    nextStep() {
        console.log('nextStep')
        this.$store.commit('getSelectSys', this.selectSyms);
        this.toStepThree();
    },
    //
    preStep() {
        this.$store.commit('getSelectSys', this.selectSyms);
        this.toStepOne();
    },
    toStepOne(){
		this.$store.commit('changeCount',1);
        this.$router.replace({path:'/container/camera'});
    },
    toStepTwo(){
        if(this.$store.state.imgSrc != ""){
            this.$store.commit('changeCount',2);
            this.$router.replace({path:'/container/search'});
        } else {
            this.$message({
                      message: '您必须先拍摄或上传一张面部图像',
                      type: 'error'
                    });
        }
    },
    toStepThree(){
        if(this.$store.state.imgSrc != ""){
            this.$store.commit('changeCount',3);
            this.$router.replace({path:'/container/table'});
        } else {
            this.$message({
                      message: '您必须先拍摄或上传一张面部图像',
                      type: 'error'
                    });
        }
    },
  },
};
</script>
<style scoped>
.box-searching {
  background:url("../assets/camera-background.png") no-repeat;
  float: left;
  background-size:100% 100%;
  margin-top: 10px;
  margin-left: 10%;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.nextstep {
    font-size: 30px;
    float: right;
    margin-right: 15px;
    color: #ffffff;
    cursor: pointer;
    position: absolute;
    right: 45px;
    top: 15px;
}
</style>
