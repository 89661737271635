 <template>
     <div class="back">
        <el-row>
          <img
            src="../assets/logo.png"
            @click="returnStart()"
            style="float: left; height: 50px; margin: 10px 20px 0px 120px;cursor: pointer;"
          />
          <span style="float: left; margin-left: 45px;font-size: 45px;color: #FFFFFF;cursor: pointer;" @click="returnStart()">
            CareRare 罕见病特征分析辅诊方案
          </span>
        </el-row>
        <el-row>
            <div class="step-background">
                <img 
                src="../assets/camerastep.png" 
                class="step-icon" 
                :style="{left:screenWidth/6+15+'px',top: stepIconBaseTop+'px'}"/>
                <img 
                src="../assets/step-icon-back.png" 
                class="step-icon" :style="{left:screenWidth/6+'px',top: stepIconBaseTop+10+'px'}"/>
                <span 
                class="step-text" 
                :style="{left: screenWidth/6+150+'px',color: stepColorOne,top: stepTextBaseTop+'px'}" 
                @click="toStepOne()">人像采集</span>
                <img 
                src="../assets/arrow.png" 
                class="step-icon" 
                :style="{left:screenWidth/6+350+'px',top: stepIconBaseTop+'px'}"/>
                <!--  -->
                <img
                src="../assets/searchstep.png" 
                class="step-icon" 
                :style="{left:screenWidth/12*5+15+'px',top: stepIconBaseTop+'px'}"/>
                <img 
                src="../assets/step-icon-back.png" 
                class="step-icon" :style="{left:screenWidth/12*5+'px',top: stepIconBaseTop+10+'px'}"/>
                <span 
                class="step-text" 
                :style="{left: screenWidth/12*5+150+'px',color: stepColorTwo,top: stepTextBaseTop+'px'}" 
                @click="toStepTwo()">病症选择</span>
                <img 
                src="../assets/arrow.png" 
                class="step-icon" 
                :style="{left:screenWidth/12*5+350+'px',top: stepIconBaseTop+'px'}"/>
                <!--  -->
                <img
                src="../assets/completestep.png" 
                class="step-icon" 
                :style="{left:screenWidth/3*2+15+'px',top: stepIconBaseTop+'px'}"/>
                <img 
                src="../assets/step-icon-back.png" 
                class="step-icon" :style="{left:screenWidth/3*2+'px',top: stepIconBaseTop+10+'px'}"/>
                <span 
                class="step-text" 
                :style="{left: screenWidth/3*2+150+'px',color: stepColorThree,top: stepTextBaseTop+'px'}" 
                @click="toStepThree()">筛查结果</span>
                <img 
                src="../assets/arrow.png" 
                class="step-icon" 
                :style="{left:screenWidth/3*2+350+'px',top: stepIconBaseTop+'px'}"/>
            </div>
        </el-row>
        <el-row>
            <router-view></router-view>
        </el-row>
        <el-row>
            <div class="footer">
                <span style="color: #8f8f8f;">谢谢体验</span>
            </div>
        </el-row>
     </div>
</template>

<script>
    export default {
        name:'container',
        data() {
          return {
            screenWidth: '',
            screenHeight: '',
            stepIconBaseTop: 25,
            stepTextBaseTop: 20,
          };
        },
        created() {
          this.stepColorPrepare();
        },
        computed:{
            temp: function() {
                return this.$store.state.count;
            },
            stepColorOne: function(){
                let color = "#FFFFFF";
                if(this.temp == 1) {
                    color = "#bdffc5";
                }
                return color;
            },
            stepColorTwo: function(){
                console.log("stepColorTwo")
                let color = "#FFFFFF";
                if(this.temp == 2) {
                    color = "#bdffc5";
                }
                return color;
            },
            stepColorThree: function(){
                console.log("stepColorThree")
                let color = "#FFFFFF";
                if(this.temp == 3) {
                    color = "#bdffc5";
                }
                return color;
            },
        },
        mounted() {
          this.screenWidth = document.documentElement.clientWidth;
          this.screenHeight = document.documentElement.clientHeight;
          // this.clientHeight = `${document.documentElement.clientHeight}`
          // console.log("document num",this.screenWidth,this.screenHeight)
          window.onresize = () => {
              console.log("width heigth", document.documentElement.clientWidth, document.documentElement.clientHeight)
            return (() => {
              this.screenWidth = document.documentElement.clientWidth;
              this.screenHeight = document.documentElement.clientHeight;
            })();
          };
        },
        methods:{
            stepColorPrepare(){
                console.log("count", this.$store.state.count)
                let temp = this.$store.state.count;
                switch(temp){
                    case 1: this.stepColorOne = "#bdffc5"; break;
                    case 2: this.stepColorTwo = "#bdffc5"; break;
                    case 3: this.stepColorThree = "#bdffc5"; break;
                    case 0: break;
                }
            },
            toStepOne(){
                this.$store.commit('changeCount',1);
                this.$router.replace({path:'/container/camera'});
            },
            toStepTwo(){
                if(this.$store.state.imgSrc != ""){
                    this.$store.commit('changeCount',2);
                    this.$router.replace({path:'/container/search'});
                } else {
                    this.$message({
                              message: '您必须先拍摄或上传一张面部图像',
                              type: 'error'
                            });
                }
            },
            toStepThree(){
                if(this.$store.state.imgSrc != ""){
                    this.$store.commit('changeCount',3);
                    this.$router.replace({path:'/container/table'});
                } else {
                    this.$message({
                              message: '您必须先拍摄或上传一张面部图像',
                              type: 'error'
                            });
                }
            },
            returnStart() {
                this.$store.commit('changeCount',0);
                this.$store.commit('getImgSrc',"");
                this.$store.commit('getSelectSys',[]);
                this.$router.replace("/");
            },
        }
      }
</script>
<style>
    .back{
        background:url("../assets/background.gif");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width:100%;
        height:100%;
        position:fixed;
        z-index: -1;
        background-size:100% 100%;
    }
    .step-icon {
        position: absolute;
    }
    .step-text {
        position: absolute;
        font-size: 25px;
        color: #FFFFFF;
        cursor: pointer;
    }
    .step-background {
        background:url(../assets/step-background.png);
        width:100%;
        height:80px;
        background-size:100% 100%;
    }
    .footer{
        background:url("../assets/footer.png");
        width:100%;
        height:30px;
        position:fixed;
    }
</style>
