 <template>
     <div class="back">
        <el-row>
          <img
            src="../assets/logo.png"
            @click="returnStart()"
            style="float: left; height: 50px; margin: 10px 20px 0px 120px;"
          />
          <span style="float: left; margin-left: 45px;font-size: 45px;color: #FFFFFF;" @click="returnStart()">
            CareRare 罕见病特征分析辅诊方案
          </span>
        </el-row>
        <el-row>
            <div class="instruction-box" :style="{width: screenWidth/5*4+'px', height: screenHeight-140+'px'}">
                    <el-carousel :interval="40000" :height="screenHeight-160+'px'">
                        <el-carousel-item>
                          <img src="../assets/1.png" :style="{width: screenWidth/5*4-100+'px', height: screenHeight-260+'px'}"/>
                          <span style="color: #FFFFFF;float: left;">1、您可以在此处通过摄像头拍摄面部图片，也可以选择从本地上传。在位置，大小需要调整时，您可选择裁剪按钮进行编辑。注：若本次筛查不希望提供面容，您也可以选择“不采集患者面容”，之后将仅根据您后续提供的人类表性信息进行推理。</span>
                        </el-carousel-item>
                        <el-carousel-item>
                          <img src="../assets/2.png" :style="{width: screenWidth/5*4-100+'px', height: screenHeight-260+'px'}"/>
                          <span style="color: #FFFFFF;float: left;">2、在此您可以输入或选择目前的病症。若您选择输入，我们会为您联想出相关病症供您选择。同时您也可以根据下拉菜单中的类别，找到您想要的病症。我们支持输入不止一个病症。</span>
                        </el-carousel-item>
                        <el-carousel-item>
                          <img src="../assets/3.png" :style="{width: screenWidth/5*4-100+'px', height: screenHeight-260+'px'}"/>
                          <span style="color: #FFFFFF;float: left;">3、最后我们会给您本次的筛查结果，结果按照可能性由高到低排序。我们将会以表格的方式将结果呈现给你，表格的三列分别为遗传病ID、遗传病名称以及诊断概率。查询需要一段时间，请您耐心等待。</span>
                        </el-carousel-item>
                      <el-carousel-item>
                          <el-row>
                              <span style="color: #FFFFFF;float: left;margin-bottom: 20px;">4、本实验结果目前仅作为参考，不作为最后诊断结果。目前该系统的提供的筛查范围包括以下病种：</span>
                          </el-row>
                          <el-row>
                              <el-carousel 
                              :interval="4000" 
                              type="card" 
                              height="600px" 
                              style="width: 80%;margin-left: 10%;"
                              arrow="always"
                              indicator-position="none ">
                                  <el-carousel-item>
                                    <img src="../assets/table1.png" style="width: 100%;height: 100%;"/>
                                  </el-carousel-item>
                                  <el-carousel-item>
                                    <img src="../assets/table2.png" style="width: 100%;height: 100%;"/>
                                  </el-carousel-item>
                                  <el-carousel-item>
                                    <img src="../assets/table3.png" style="width: 100%;height: 100%;"/>
                                  </el-carousel-item>
                                  <el-carousel-item>
                                    <img src="../assets/table4.png" style="width: 100%;height: 100%;"/>
                                  </el-carousel-item>
                                </el-carousel>
                          </el-row>
                        </el-carousel-item>
                      </el-carousel>
                </div>
        </el-row>
        <el-row>
            <div class="footer">
                <span style="color: #8f8f8f;">谢谢体验</span>
            </div>
        </el-row>
     </div>
</template>

<script>
    export default {
        name:'instruction',
        data() {
          return {
            screenWidth: '',
            screenHeight: '',
            stepIconBaseTop: 25,
            stepTextBaseTop: 20,
            stepColorOne: "#FFFFFF",
            stepColorTwo: "#FFFFFF",
            stepColorThree: "#FFFFFF",
          };
        },
        mounted() {
          this.screenWidth = document.documentElement.clientWidth;
          this.screenHeight = document.documentElement.clientHeight;
          // this.clientHeight = `${document.documentElement.clientHeight}`
          // console.log("document num",this.screenWidth,this.screenHeight)
          window.onresize = () => {
              console.log("width heigth", document.documentElement.clientWidth, document.documentElement.clientHeight)
            return (() => {
              this.screenWidth = document.documentElement.clientWidth;
              this.screenHeight = document.documentElement.clientHeight;
            })();
          };
        },
        methods:{
            returnStart() {
                this.$store.commit('changeCount',0);
                this.$router.replace("/");
            },
        }
      }
</script>
<style>
    .back{
        background:url("../assets/background.gif");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width:100%;
        height:100%;
        position:fixed;
        z-index: -1;
        background-size:100% 100%;
    }
    .footer{
        background:url("../assets/footer.png");
        width:100%;
        height:30px;
        position:fixed;
    }
    .instruction-box {
      background:url("../assets/camera-background.png") no-repeat;
      float: left;
      background-size:100% 100%;
      margin-top: 20px;
      margin-left: 10%;
    }
</style>
