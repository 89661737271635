<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view></router-view>
   <!-- <IvView></IvView> -->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import IvView from './components/IvView.vue';
export default {
  name: 'App',
  components: {
    // HelloWorld,
  },
  computed: {
    windowHeight(val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
      return val;
    },
    windowWidth(val) {
      let that = this;
      console.log("实时屏幕宽度：", val, that.windowHeight);
      return val;
    }
  },
}
</script>

<style>
#app {
  background-image: url(assets/backGIf.gif);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.el-cascader-menu {
    height: 400px;
}
.el-cascader-menu__wrap.el-scrollbar__wrap {
    height: 400px;
}
.el-carousel__arrow.el-carousel__arrow--right{
    font-size: 30px;
}
.el-carousel__arrow.el-carousel__arrow--left{
    font-size: 30px;
}
</style>
