import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js' //注意：这里引入的是router目录，百度上有好些是引入vue-router
import store from './store.js'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  // 需要配置的属性 注意属性并没有引号
  title: false,
  toolbar: false
})



var axios = require('axios')
axios.defaults.timeout = 30000;
// 分页组件
import Pagination from "@/components/Pagination";
// 将API方法绑定到全局
Vue.prototype.$axios = axios;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.component('Pagination', Pagination)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
