<template>
  <div>
      <el-row>
          <div class="box" :style="{width: screenWidth/5*4+'px',height: screenHeight-300+'px'}">
              <el-table v-loading="loading" :data="tableData" style="width: 100%;" :max-height="screenHeight-310+'px'">
                        <!-- <el-table-column prop="date" label="日期" width="180"></el-table-column> -->
                        <el-table-column prop="id" label="遗传病ID" :width="screenWidth/5"></el-table-column>
                        <el-table-column label="遗传病名称" :width="screenWidth/5*2">
                          <template #default="scope">{{ scope.row.diease || scope.row.disease_en }}</template>
                        </el-table-column>
                        <el-table-column prop="probility" label="诊断概率" :width="screenWidth/5"></el-table-column>
              </el-table>
          </div>
      </el-row>
      <el-row style="margin-top: 10px;">
          <div @click="returnStart" style="position: absolute;" :style="{left: screenWidth/10+'px'}">
              <img src="../assets/next-button.png" style="cursor: pointer;"/>
              <span class="nextstep" style="right: 30px;">返回首页</span>
          </div>
          <div v-if="isLogin" @click="toHis" style="position: absolute;" :style="{right: screenWidth/10+ 300+'px'}">
              <img src="../assets/next-button.png" style="cursor: pointer;"/>
              <span class="nextstep">诊断记录</span>
          </div>
          <div @click="preStep" style="position: absolute;" :style="{right: screenWidth/10+'px'}">
              <img src="../assets/next-button.png" style="cursor: pointer;"/>
              <span class="nextstep">上一步</span>
          </div>
      </el-row>
  </div>
</template>

<script>
import axios from "axios";
const careurl = "https://ai.visioncarewsk.com";
const baseUrl = 'https://iv.apisev.cn'
import Cookie from 'js-cookie';

import SnowflakeId from "snowflake-id";
const id= new SnowflakeId();
export default {
  name: "table",
  data() {
    return {
        screenWidth: '',
        screenHeight: '',
      tableData: [],
      loading: false,
      isLogin:false
    };
  },
  created() {
    let token = Cookie.get('token')
    if(token != undefined && token != null){
      this.isLogin = true;
    }
    //请求后台并且进行groupBy操作
    this.submit();
  },
  mounted() {
    this.screenWidth = document.documentElement.clientWidth;
    this.screenHeight = document.documentElement.clientHeight;
    // this.clientHeight = `${document.documentElement.clientHeight}`
    // console.log("document num",this.screenWidth,this.screenHeight)
    window.onresize = () => {
        console.log("width heigth", document.documentElement.clientWidth, document.documentElement.clientHeight)
      return (() => {
        this.screenWidth = document.documentElement.clientWidth;
        this.screenHeight = document.documentElement.clientHeight;
      })();
    };
  },

  methods: {
    toHis(){
      this.$router.push('/history');
    },
    // 存储诊断结果
    saveRes(){

    },
    submit() {
      var that = this;
      //this.imgSrc = this.imgSrc.replace(/^data:image\/\w+;base64,/, "");
      this.loading = true;
      console.log("this.imgSrc", this.$store.state);
      let baseImg = this.$store.state.imgSrc;
      // baseImg = baseImg.replace(/\+/g, "%2B");
      // baseImg = baseImg.replace(/\=/g, "&3D");
      axios.defaults.timeout = 30000;
      //   console.log(encodeURI(baseImg))
      let imageName = id.generate();
      axios({
        method: "post",
        url: careurl + "/cn_photo",
        headers: {
          id: "1",
        },
        data: {
          photo: baseImg,
          selectSyms: this.$store.state.selectSyms,
          photo_name:imageName
        },
      })
        .then((response) => {
          //这里使用了ES6的语法
          console.log(response); //请求成功返回的数据
          this.tableData = response.data.all_mess;
          that.loading = false;
          axios({
            method:'post',
            url: baseUrl+'/iv/history',
            headers:{
              token:Cookie.get('token')
            },
            data:{
              selectSyms: this.$store.state.selectSyms,
              patientId:Cookie.get('selectedPatientId'),
              result:JSON.stringify(this.tableData),
              imageName:imageName
            }
          }).then(res=>{
            console.log('保存记录')
            if(res.data.code == 200){
              this.$message({
              message: '诊断结果保存成功',
              type: 'success'
            });
            }
            console.log(res)
          })
        })
        .catch((e) => {
          console.log(e);
        });
    },
    preStep() {
        this.toStepTwo();
    },
    toStepOne(){
		this.$store.commit('changeCount',1);
        this.$router.replace({path:'/container/camera'});
    },
    toStepTwo(){
        if(this.$store.state.imgSrc != ""){
            this.$store.commit('changeCount',2);
            this.$router.replace({path:'/container/search'});
        } else {
            this.$message({
                      message: '您必须先拍摄或上传一张面部图像',
                      type: 'error'
                    });
        }
    },
    toStepThree(){
        if(this.$store.state.imgSrc != ""){
            this.$store.commit('changeCount',3);
            this.$router.replace({path:'/container/table'});
        } else {
            this.$message({
                      message: '您必须先拍摄或上传一张面部图像',
                      type: 'error'
                    });
        }
    },
    returnStart() {
        this.$store.commit('changeCount',0);
		this.$store.commit('getImgSrc',"");
		this.$store.commit('getSelectSys',[]);
        this.$router.replace("/");
    },
  },
};
</script>
<style scoped>

.box {
  background:url("../assets/camera-background.png") no-repeat;
  background-size:100% 100%;
  margin-top: 10px;
  margin-left: 10%;
  padding-top: 10px;
}
.nextstep {
    font-size: 30px;
    float: right;
    margin-right: 15px;
    color: #ffffff;
    cursor: pointer;
    position: absolute;
    right: 45px;
    top: 15px;
}
</style>
