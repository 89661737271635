<template>
  <div>
    <el-row>
      <el-col :span="5">
        <el-row>
          <img src="../assets/camera.png" style="float: right;position: absolute;top: 20px;right: 35px;cursor: pointer;"
            @click="drawImage" />
          <img src="../assets/method-background.png" style="float: right;cursor: pointer;" @click="drawImage" />
        </el-row>
        <el-row>
          <el-button type="text" class="el-button--text" @click="drawImage">拍照</el-button>
        </el-row>
        <el-row v-show="ifUpload">
          <cuttingdialog :url="url" v-show="ifUpload" v-on:urlchange="updateUrl"></cuttingdialog>
        </el-row>
        <el-row>
          <img src="../assets/reset.png" style="float: right;position: absolute;top: 20px;right: 35px;cursor: pointer;"
            @click="resetCanvas" />
          <img src="../assets/method-background.png" style="float: right;cursor: pointer;" @click="resetCanvas" />
        </el-row>
        <el-row>
          <el-button type="text" class="el-button--text" @click="resetCanvas">重置</el-button>
        </el-row>

        <!--患者选择-->
        <el-row v-if="isLogin">
          <img src="../assets/patient.png"
            style="float: right;position: absolute;top: 20px;right: 35px;cursor: pointer;" @click="show = true" />
          <img src="../assets/method-background.png" style="float: right;cursor: pointer;" @click="show = true" />
        </el-row>
        <el-row v-if="isLogin">
          <el-button type="text" class="el-button--text" @click="show = true">患者</el-button>
        </el-row>
        <el-row>
          <el-upload action="#" :http-request="requestUpload" :show-file-list="false" :before-upload="beforeUpload"
            :on-success="succesUpload" style="float: right;margin-right: 6px;">
            <img src="../assets/upload.png"
              style="float: right;position: absolute;top: 20px;right: 40px;cursor: pointer;" />
            <img src="../assets/method-background.png" style="float: right;cursor: pointer;" />
          </el-upload>
        </el-row>
        <el-row>
          <el-upload action="#" :http-request="requestUpload" :show-file-list="false" :before-upload="beforeUpload"
            :on-success="succesUpload" style="float: right;margin-right: 6px;">
            <el-button type="text" class="el-button--text">上传</el-button>
          </el-upload>
        </el-row>
      </el-col>
      <el-col :span="14" :offset="1">
        <div class="box-camera" :style="{width:screenWidth/2+'px',height: screenHeight-200+'px'}">

          <video id="videoCamera" class="canvas" style="margin-top: 5px;" :width="screenWidth/2+'px'"
            :height="screenHeight-220+'px'" autoplay v-show="isPic == false"></video>
          <!--图片蒙层-->
          <img class="canvas" v-show="ifLoading" :width="screenWidth/2+'px'" :height="screenHeight-210+'px'"
            src="../assets/loading.gif" style="z-index: 1000; position: absolute" />
          <img class="canvas" :width="screenWidth/2+'px'" :height="screenHeight-210+'px'" v-show="!ifLoading"
            src="https://img-blog.csdnimg.cn/20210126152753150.png" style="z-index: 999; position: absolute;" />
          <canvas id="canvasCamera" class="canvas" style="margin-top: 5px;" :width="screenWidth/2+'px'"
            :height="screenHeight-210+'px'" v-show="isPic == true">
            <img id="uploadPic" :src="url" />
          </canvas>
        </div>
      </el-col>
      <el-col :span="4" :offset="15">
        <a href="http://ds.visioncarewsk.com/index" style="position: absolute;right: 45px;top: 20px;" target="_blank">
          <img src="../assets/next-button.png" style="cursor: pointer;" />
          <span class="nextstep" style="right: 5px;font-size: 25px;top: 25px;">不采集患者面容</span>
        </a>
        <a v-if="isLogin" href="/history" style="position: absolute;right: 45px;top: 120px;" target="_blank">
          <img src="../assets/next-button.png" style="cursor: pointer;" />
          <span class="nextstep" style="right: 5px;font-size: 25px;top: 25px;">查看诊断记录</span>
        </a>
        <div @click="nextStep" style="position: absolute;right: 45px;bottom: 0px;">
          <img src="../assets/next-button.png" style="cursor: pointer;" />
          <span class="nextstep">下一步</span>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="患者选择" :visible.sync="show" width="30%" :append-to-body="true" style="z-index:999">

      <el-form ref="patientRef" :model="form" label-width="100px">

        <el-form-item label="患者姓名" prop="patientName">
          <el-select v-model="form.patientName" @change="selectPatient" filterable :remote="true"
            :remote-method="matchPatient" placeholder="请输入患者姓名">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系方式" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入患者联系方式" />
        </el-form-item>
        <el-form-item label="身份ID" prop="patientId">
          <el-input v-model="form.patientId" placeholder="请输入患者身份证号" />
        </el-form-item>
        <!-- <el-form-item label="患者性别" prop="patientSex">
          <el-input v-model="form.patientSex" placeholder="请输入患者性别（男/女）" />
        </el-form-item> -->
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="show = false">取 消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { checkbeforepersonalphoto } from "../api/api";
import cuttingdialog from "./CuttingDialog.vue";
import Cookie from 'js-cookie';
import axios from 'axios';
const baseUrl = 'https://iv.apisev.cn'
import Cookies from 'js-cookie';
export default {
  name: "camera",
  components: { cuttingdialog },
  props: {
    msg: String,
  },
  mounted() {
    this.screenWidth = document.documentElement.clientWidth;
    this.screenHeight = document.documentElement.clientHeight;
    // this.clientHeight = `${document.documentElement.clientHeight}`
    // console.log("document num",this.screenWidth,this.screenHeight)
    window.onresize = () => {
      console.log("width heigth", document.documentElement.clientWidth, document.documentElement.clientHeight)
      return (() => {
        this.screenWidth = document.documentElement.clientWidth;
        this.screenHeight = document.documentElement.clientHeight;
      })();
    };
  },
  data() {
    return {
      //选择的症状
      screenWidth: '',
      screenHeight: '',
      stepColorOne: "#FFFFFF",
      stepColorTwo: "#FFFFFF",
      stepColorThree: "#FFFFFF",
      ifLoading: true,
      url: "", // 上传的图片的地址
      visible: false, //弹窗
      videoWidth: 800, // 绘画布的宽高
      videoHeight: 800,
      os: false, //控制摄像头开关
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      imgSrc: undefined,
      imgFile: null,
      isPic: false,
      isUploadPic: false,
      tableData: [],
      loading: false,
      ifUpload: false,
      nextTip: false,
      show: false,
      form: {},
      options: [],
      // 选中的患者ID
      selectedPatientId: null,
      selectedPatientName:null,
      isLogin:false
    };
  },
  created() {
    // 建议初始化患者信息？
    let token = Cookies.get('token')
    if(token != undefined && token != null){
      this.isLogin = true;
    }
    this.onTake();
    this.stepColorPrepare();
  },
  methods: {
    // 表单提交，或者选择
    submitForm() {
      if (this.selectedPatientId == null) {
        // 先创建
        axios({
          url: baseUrl + '/iv/patient',
          method: 'post',
          headers:{
              token:Cookie.get('token')
            },
          data: { ...this.form }
        }).then(res => {
          console.log(res)
        
          this.show = false;
          if (res.data.code === 200) {
            this.selectedPatientId = res.data.data.id;
          Cookie.set('selectedPatientId', this.selectedPatientId);
            this.$message({
              message: '选择完成',
              type: 'success'
            });
            this.selectedPatientId = res.data.data.id;
            this.show = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      } else {
        // 缓存数据
        Cookie.set('selectedPatientId', this.selectedPatientId);
        this.show = false;
      }
    },
    // 匹配患者
    matchPatient(e) {
      let query = e;
      Vue.set(this.form, 'patientName', query)
      console.log(query)
      axios({
        url: baseUrl + '/iv/patient/all?name=' + query,
        method: 'get'
      }).then(res => {
        console.log(res)
        let tarr = res.data.data;
        tarr = tarr.map(ele => {
          ele.label = ele.patientName + '(' + ele.patientId + ')'
          ele.value = ele.id
          return ele;
        })
        this.options = tarr
      })
    },
    selectPatient(e) {
      console.log(e)
      this.selectedPatientId = e;
      let item = null;
      this.options.forEach(ele => {
        if (ele.id == e) {
          item = ele;
        }
      })
      console.log(item)
      this.form = item;
    },
    stepColorPrepare() {
      console.log("count", this.$store.state.count)
      let temp = this.$store.state.count
      if (temp == 1) {
        this.stepColorOne = "#bdffc5";
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file);
        //开始转
        reader.onload = function () {
          fileResult = reader.result;
        };
        //转 失败
        reader.onerror = function (error) {
          reject(error);
        };
        //转 结束  咱就 resolve 出去
        reader.onloadend = function () {
          resolve(fileResult);
        };
      });
    },

    /*调用摄像头拍照开始*/
    onTake() {
      setTimeout(() => {
        this.visible = true;
        // this.ifLoading = false;
        this.getCompetence();
      }, 2000);

    },

    /*关闭弹窗，以及关闭摄像头功能*/
    onCancel1() {
      this.visible = false;
      this.stopNavigator(); // 关闭摄像头
    },

    // 调用摄像头权限
    getCompetence() {
      //必须在model中render后才可获取到dom节点,直接获取无法获取到model中的dom节点
      this.$nextTick(() => {
        const _this = this;
        this.os = false; //切换成关闭摄像头
        // 获取画布节点
        this.thisCancas = document.getElementById("canvasCamera");
        // 为画布指定绘画为2d类型
        this.thisContext = this.thisCancas.getContext("2d");
        //获取video节点
        this.thisVideo = document.getElementById("videoCamera");
        // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
        if (navigator.mediaDevices === undefined) {
          navigator.menavigatordiaDevices = {};
        }
        // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
        // 使用getUserMedia，因为它会覆盖现有的属性。
        // 这里，如果缺少getUserMedia属性，就添加它。
        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = function (constraints) {
            // 首先获取现存的getUserMedia(如果存在)
            let getUserMedia =
              navigator.webkitGetUserMedia ||
              navigator.mozGetUserMedia ||
              navigator.getUserMedia;
            // 有些浏览器不支持，会返回错误信息
            // 保持接口一致
            if (!getUserMedia) {
              return Promise.reject(
                new Error("getUserMedia is not implemented in this browser")
              );
            }
            // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
            return new Promise(function (resolve, reject) {
              getUserMedia.call(navigator, constraints, resolve, reject);
            });
          };
        }
        const constraints = {
          audio: false,
          video: {
            width: _this.screenWidth / 2 - 10,
            height: _this.screenHeight - 220,
            transform: "scaleX(-1)",
          },
        };
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(function (stream) {
            // 旧的浏览器可能没有srcObject
            if ("srcObject" in _this.thisVideo) {
              _this.thisVideo.srcObject = stream;
            } else {
              // 避免在新的浏览器中使用它，因为它正在被弃用。
              _this.thisVideo.src = window.URL.createObjectURL(stream);
            }
            _this.thisVideo.onloadedmetadata = function (e) {
              console.log("e", e);
              _this.thisVideo.play();
              //取消Loading
              _this.ifLoading = false;
            };
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              title: "警告",
              message: "没有开启摄像头权限或浏览器版本不兼容.",
              type: "warning",
            });
          });
      });
    },

    //调用摄像头 --- 进行绘制图片
    drawImage() {
      // 点击，canvas画图
      console.log("this.thisVideo", this.thisVideo);
      this.thisContext.drawImage(
        this.thisVideo,
        0,
        0,
        this.screenWidth / 2 - 10,
        this.screenHeight - 210
      );
      // 获取图片base64链接
      this.imgSrc = this.thisCancas.toDataURL();
      this.isPic = true;
      console.log("this.imgSrc", this.thisCancas);

      /*const imgSrc=this.imgSrc;*/
    },
    //清空画布
    clearCanvas(id) {
      let c = document.getElementById(id);
      let cxt = c.getContext("2d");
      cxt.clearRect(0, 0, c.width, c.height);
      console.log("resetComplete");
    },

    //重置画布
    resetCanvas() {
      // this.imgSrc = "";
      this.isPic = false;
      this.ifUpload = false;
      this.tableData = [];
      this.clearCanvas("canvasCamera");
    },
    //
    //
    beforeUpload(file) {
      if (file.type.indexOf("image/") == -1) {
        this.$modal.msgError(
          "文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。"
        );
      } else {
        this.clearCanvas("canvasCamera");
        const reader = new FileReader();
        reader.readAsDataURL(file);
        let img = document.getElementById("uploadPic");
        console.log("reader", reader, img);
        reader.onload = () => {
          this.url = reader.result;
          // console.log("this.url", this.url);
        };
        setTimeout(() => {
          this.thisContext.drawImage(
            img,
            0,
            0,
            this.screenWidth / 2 - 10,
            this.screenHeight - 220,
          );
          // 获取图片base64链接
          this.imgSrc = this.thisCancas.toDataURL();
          this.isPic = true;
          // console.log("this.imgSrc", this.thisCancas);
        }, 1000);
        this.ifUpload = true;
      }
    },
    //
    updateUrl(data) {
      this.url = data;
      let img = document.getElementById("uploadPic");
      setTimeout(() => {
        this.thisContext.drawImage(
          img,
          0,
          0,
          this.screenWidth / 2 - 10,
          this.screenHeight - 220,
        );
        // 获取图片base64链接
        this.imgSrc = this.thisCancas.toDataURL();
        this.isPic = true;
        console.log("this.imgSrc", this.thisCancas);
      }, 100);
    },
    //
    succesUpload(response, file) {
      console.log("response", response);
      console.log("file", file);
    },
    //
    requestUpload() { },

    //关闭摄像头
    stopNavigator() {
      if (this.thisVideo && this.thisVideo !== null) {
        this.thisVideo.srcObject.getTracks()[0].stop();
        this.os = true; //切换成打开摄像头
        this.isPic = false;
      }
    },
    /*调用摄像头拍照结束*/

    /*完成拍照并对其照片进行上传*/
    onCancel() {
      this.visible = false;
      /* this.resetCanvas();*/
      // console.log(this.imgSrc);
      this.imgFile = this.dataURLtoFile(this.imgSrc, new Date() + ".png");
      console.log(this.imgFile);
      this.stopNavigator();
      // let par = {
      //   photo: this.imgFile,
      // };
      let data = new FormData();
      data.append("photo", this.imgFile); //1是图片，2是视频
      // data.append("code", this.addForm.code);
      // console.log(data);
      // checkbeforepersonalphoto上传图片的接口
      checkbeforepersonalphoto(data).then((res) => {
        if (res.code == "1") {
          this.$message({
            message: "上传成功",
            type: "success",
          });
          this.url = res.data;
          // console.log('url', this.url);
        }
      });
    },

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    //
    nextStep() {
      let token = Cookie.get('token')
      console.log(token)
      if (token != undefined && this.selectedPatientId == null) {
        this.$message({
          message: '请选择就诊患者',
          type: 'warning'
        });
        this.show = true;
      } else {
        if (this.imgSrc == "" || this.imgSrc == undefined) {
          this.$message({
            message: '您必须先拍摄或上传一张面部图像',
            type: 'error'
          });
        } else {
          console.log('nextStep', this.imgSrc);
          this.$store.commit('getImgSrc', this.imgSrc);
          this.toStepTwo();
        }
      }

    },
    noImage() {

    },
    toStepOne() {
      this.$router.replace({ path: '/container/camera' });
    },
    toStepTwo() {
      if (this.$store.state.imgSrc != "") {
        this.$store.commit('changeCount', 2);
        this.$router.replace({ path: '/container/search' });
      } else {
        this.$message({
          message: '您必须先拍摄或上传一张面部图像',
          type: 'error'
        });
      }
    },
    toStepThree() {
      if (this.$store.state.imgSrc != "") {
        this.$store.commit('changeCount', 3);
        this.$router.replace({ path: '/container/table' });
      } else {
        this.$message({
          message: '您必须先拍摄或上传一张面部图像',
          type: 'error'
        });
      }
    },
    returnStart() {
      this.$store.commit('changeCount', 0);
      this.$router.replace("/");
    },
  },
};
</script>
<style scoped>
.box-camera {
  display: flex;
  background: url("../assets/camera-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.el-button--text {
  font-size: 25px;
  float: right;
  margin-right: 20px;
  color: #ffffff;
  cursor: pointer;
}

.nextstep {
  font-size: 30px;
  float: right;
  margin-right: 15px;
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  right: 45px;
  top: 15px;
}
</style>
