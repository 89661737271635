import axios from 'axios'
axios.defaults.withCredentials = true

// 上传图片地址
export const url1 = "https://ai.visioncarewsk.com/cn_photo";

axios.interceptors.request.use(config => {
  if (sessionStorage.getItem("sessionId")) {
    config.headers.AUTHORIZATION = window.sessionStorage.getItem("sessionId")
  } else {
    config.headers.AUTHORIZATION = "Authorization"
  }
  return config
})

export const checkbeforepersonalphoto = params => {
  return axios.post(`${url1}/checkbefore/personal/photo`,
    params).then(res => res.data);
};


